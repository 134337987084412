import React from 'react';
import './Switch.css';
import { Control, useController } from 'react-hook-form';
import { twMerge } from 'src/lib/mergeTailwind';
import { useResponsiveVariant } from 'src/lib/hooks';
import { Responsive } from 'src/lib/hooks/hooks';

type SwitchSize = 'normal' | 'large';

type SwitchType = {
  control: Control;
  name: string;
  id?: string;
  hiddenLabel?: boolean;
  className?: string;
  size?: Responsive<SwitchSize>;
  disabled?: boolean;
  showYesNo?: boolean;
};

const Switch = ({
  control,
  name,
  id,
  hiddenLabel = false,
  className,
  size = 'normal',
  disabled = false,
  showYesNo = true,
}: SwitchType) => {
  const { field } = useController({
    name,
    control,
    defaultValue: false,
  });

  const sizeClass = useResponsiveVariant(size);

  return (
    <div className={twMerge('flex flex-row gap-x-4 h-6', className)}>
      {showYesNo && (
        <span
          className={twMerge(
            'text-black font-bold my-auto',
            hiddenLabel && 'sr-only'
          )}
        >
          No
        </span>
      )}
      <div className="mt-1">
        <input
          className="react-switch-checkbox"
          id={id ?? name}
          type="checkbox"
          onChange={(event) => {
            field.onChange(event.target.checked); // data send back to hook form
          }} // send value to hook form
          onBlur={field.onBlur} // notify when input is touched/blur
          aria-checked={field.value} // input value
          name={field.name} // send down the input name
          ref={field.ref} // send input ref, so we can focus on input when error appear
          value={field.value}
          checked={field.value}
          disabled={disabled}
          data-testid={`${id ?? name}.switch`}
        />
        <label
          className={twMerge('react-switch-label', sizeClass)}
          aria-label={field.name}
          htmlFor={id ?? name}
        >
          <span className={twMerge('react-switch-button', sizeClass)} />
        </label>
      </div>
      {showYesNo && (
        <span
          className={twMerge(
            'text-black font-bold my-auto',
            hiddenLabel && 'sr-only'
          )}
        >
          Yes
        </span>
      )}
    </div>
  );
};

export default Switch;
