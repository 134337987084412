import React, { useCallback, useEffect } from 'react';
import useFile from 'src/lib/hooks/useFile';
import Input from '../Input';
import Icon from '../Icon';
import { Control, RegisterOptions } from 'react-hook-form';
import { twMerge } from 'src/lib/mergeTailwind';
const _15MB = 15728640;

type FileFieldInputProps = {
  control: Control;
  name: string;
  defaultValue: File;
  rules?: RegisterOptions;
  containerClassName: string;
  extensions: string[];
  limitSize?: number;
  maxFileNameLength?: number;
  onValidate?: () => string | undefined;
  disabled?: boolean;
};

const FileFieldInput = ({
  control,
  name,
  defaultValue,
  rules,
  containerClassName,
  extensions,
  limitSize = _15MB,
  maxFileNameLength = 100,
  onValidate = () => undefined,
  disabled = false,
}: FileFieldInputProps) => {
  const {
    error,
    file,
    setError,
    setFile,
    field,
    openFileBrowser,
    removeSelectedFile,
    validateFileExtension,
    validateFileSize,
    validateFileNameLength,
  } = useFile({
    name,
    control,
    rules,
    defaultValue,
    options: {
      extensions,
      limitSize,
      maxFileNameLength,
    },
  });

  const { value, onChange, ref } = field;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];
    if (file) {
      setFile(file);
      onChange(file);
    } else {
      onChange(null);
    }
  };

  const validateFile = useCallback(
    (file: File) => {
      setError(undefined);

      if (file) {
        if (!validateFileNameLength(file)) {
          setError('File name cannot be longer than 100 characters');
        }

        if (!validateFileSize(file)) {
          setError(`The uploaded file exceeds the 15MB limit.`);
        }

        if (!validateFileExtension(file)) {
          setError(`The uploaded file extension is not supported.`);
        }
      }

      const error = onValidate();
      if (error) {
        setError(error);
      }
    },
    [
      onValidate,
      setError,
      validateFileExtension,
      validateFileNameLength,
      validateFileSize,
    ]
  );

  useEffect(() => {
    validateFile(value);
  }, [onValidate, validateFile, value]);

  return (
    <div className={twMerge('relative', containerClassName)}>
      <label htmlFor={name} className="sr-only">
        File
      </label>
      <Input
        type="text"
        className={twMerge(
          'sm:placeholder-gray-400',
          file ? 'px-9' : 'placeholder-transparent pr-32',
          disabled ? 'bg-disabled-input' : ''
        )}
        placeholder={
          file
            ? disabled
              ? 'File already uploaded.'
              : file.name
            : 'Choose file to upload'
        }
        readOnly
        error={error}
      />
      <input
        id={name}
        ref={ref}
        type="file"
        className="hidden "
        // eslint-disable-next-line react/no-unknown-property
        placeholder={
          file
            ? disabled
              ? 'File already uploaded.'
              : file.name
            : 'Choose file to upload'
        }
        onChange={handleFileChange}
        accept={extensions.join(', ')}
        data-testid={`${name}.fileFieldInput`}
      />
      {file ? (
        <>
          <Icon
            name="paperClip"
            size="md"
            className="absolute block top-3 left-3"
            disabled
          ></Icon>
          {!disabled && (
            <Icon
              name="trashCan"
              size="lg"
              className="absolute block top-2.5 right-3 cursor-pointer"
              color="error"
              onClick={removeSelectedFile}
              data-testid={`${name}.removeAttachment`}
              hidden={disabled}
            ></Icon>
          )}
        </>
      ) : (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
        <span
          className="flex absolute top-3 right-2/4 translate-x-1/2 sm:translate-x-0 sm:right-3 cursor-pointer file-input-center-button"
          onClick={openFileBrowser}
          data-testid={`${name}.addAttachment`}
        >
          <Icon name="paperClip" size="md"></Icon>
          <span className="text-sm text-violet font-bold underline whitespace-nowrap">
            Add Attachment
          </span>
        </span>
      )}
    </div>
  );
};

export default FileFieldInput;
