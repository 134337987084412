import React from 'react';
import * as icons from 'src/assets/icons';
import { useResponsiveVariant } from 'src/lib/hooks';
import { Responsive } from 'src/lib/hooks/hooks';
import { twMerge } from 'src/lib/mergeTailwind';

type Size =
  | 'sm'
  | '2sm'
  | 'base'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | '2xl'
  | '2xl+'
  | '3xl'
  | '4xl'
  | '6xl'
  | '300px'
  | '83px';

export type IconSize = Responsive<Size>;

export type IconColor =
  | 'violet'
  | 'violet-faded'
  | 'white'
  | 'black'
  | 'curious-blue'
  | 'dove-gray-lightest'
  | 'piper'
  | 'mystic'
  | 'error'
  | 'none';

export type IconName = keyof typeof icons | 'none';

// Define el tipo de las props
interface IconProps extends React.SVGProps<SVGSVGElement> {
  name: IconName; // Asegura que 'name' corresponda a una clave de icons
  size?: IconSize;
  color?: IconColor;
  className?: string;
  disabled?: boolean;
  hidden?: boolean;
}

const Icon: React.FC<IconProps> = ({
  name,
  size = 'base',
  color = 'violet',
  className = '',
  disabled = false,
  ...rest
}) => {
  const variantSize = useResponsiveVariant(size || 'base');

  const Component = name !== 'none' && icons[name];
  if (!Component) {
    return <div />;
  }

  return (
    <Component
      className={twMerge(
        color === 'violet' && 'fill-current text-violet',
        color === 'violet-faded' && 'fill-current text-violet-faded',
        color === 'white' && 'fill-current text-white',
        color === 'black' && 'fill-current text-black',
        color === 'curious-blue' && 'fill-current text-curious-blue',
        color === 'dove-gray-lightest' &&
          'fill-current text-dove-gray-lightest',
        color === 'piper' && 'fill-current text-piper',
        color === 'mystic' && 'fill-current text-mystic',
        color === 'error' && 'fill-current text-error',
        color === 'none' && '',
        variantSize === 'sm' && 'w-3 h-3',
        variantSize === '2sm' && 'w-3.5 h-3.5',
        variantSize === 'base' && 'w-4 h-4',
        variantSize === 'md' && 'w-5 h-5',
        variantSize === 'lg' && 'w-6 h-6',
        variantSize === 'xl' && 'w-10 h-10',
        variantSize === 'xxl' && 'w-16 h-16',
        variantSize === '2xl' && 'w-20 h-20',
        variantSize === '2xl+' && 'w-24 h-24',
        variantSize === '3xl' && 'w-28 h-28',
        variantSize === '4xl' && 'w-32 h-32',
        variantSize === '6xl' && 'w-64 h-64',
        variantSize === '300px' && 'w-[300px] h-[300px]',
        variantSize === '83px' && 'w-83px h-83px',
        disabled && 'opacity-50',
        className
      )}
      disabled={disabled}
      focusable="false"
      aria-hidden="true"
      {...rest}
    />
  );
};

export default Icon;
