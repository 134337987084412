import merge from 'lodash.merge';

import { api } from 'src/lib/hooks/useApi/useApi';
import {
  windMitTransform,
  coveragesTransform,
  quoteTransform,
  convertPropertyToNumber,
} from 'src/lib/transforms';
import { cleanEmpty } from 'src/lib/utils';
import { PRODUCTS } from 'src/products/products';
import { PAGES } from 'src/products/shared/constants';

const defaultState = {
  FirstName: '',
  LastName: '',
  Property: '',
  State: 'FL',
};

const quote = {
  state: {},
  reducers: {
    'resetQuote': (_, updatedQuote) => merge({}, defaultState, updatedQuote),
    'setQuote': (state, updatedQuote) => merge({}, state, updatedQuote),
    'global/reset': (state) => defaultState,
  },
  effects: (dispatch) => ({
    resume: async ({ ID, PolicyNumber, ResumeFrom }) => {
      let { data } = await api.secure.get(`/quote/${ID}`);
      const { FirstName, LastName } = data.Customer;

      data = quoteTransform.input(data);

      if (PolicyNumber) {
        dispatch.session.setSession({
          policy: {
            policyNumber: PolicyNumber,
          },
        });
      }

      if (
        data.ResumeFrom === PAGES.OccupancyPage &&
        data.FormType === PRODUCTS.FLORIDA_HO3.FormType
      ) {
        await dispatch.session.stageChanges(data);
      }

      const quote = {
        ...data,
        FirstName,
        LastName,
        State: 'FL',
        ID,
      };
      dispatch.quote.setQuote(quote);
    },

    newQuote: async (initialQuote) => {
      initialQuote.Property = { Location: initialQuote.PropertyAddress };
      delete initialQuote.PropertyAddress;
      const { data } = await api.secure.post('/quote', initialQuote);

      const { ID, QuoteNumber, Premium, MissingInfo } = data;

      const quote = {
        ID,
        Premium,
        RequiredMissingInfo: MissingInfo,
        QuoteNumber,
        ...initialQuote,
      };

      quote.PropertyAddress = { ...quote.Property.Location };
      delete quote.Property;
      await dispatch.quote.setQuote(quote);

      return quote;
    },

    upsertMissingInfo: async (
      { MissingInfo, Interview, Session, MissingInfoToDefault },
      root
    ) => {
      const { ID } = root.quote;

      const interviewMissingInfo = {
        OccupancyType: Interview.OccupancyType,
        PlumbingType: Interview.PlumbingType,
        HighRiseConstruction: Interview.HighRiseConstruction,
        FloorNumber: Interview.FloorNumber,
        FloorsAboveUnit: Interview.FloorsAboveUnit,
        NumberOfStories: Interview.NumberOfStories,
        HotWaterHeater: Interview.HotWaterHeater,
        UnitsAboveOrBelow: Interview.UnitsAboveOrBelow,
      };

      await api.secure.patch(`/quote/${ID}/missing-info`, {
        ID,
        AssociationId: root.user.email,
        MissingInfo,
        Interview: cleanEmpty(interviewMissingInfo),
        MissingInfoToDefault,
        FormType: root.quote.FormType,
        ...Session,
      });

      await dispatch.quote.fetchQuote();
    },

    setPackage: async (quote, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.put(`/quote/${ID}/package`, {
        ID,
        ...quote,
      });
      const { Premium } = data;
      await dispatch.quote.setQuote({
        Premium,
      });
    },

    updateQuote: async (updates, root) => {
      const { ID } = root.quote;

      const { data: currentQuote } = await api.secure.patch(`/quote/${ID}`, {
        ...updates,
        FormType: root.quote.FormType,
      });

      dispatch.quote.setQuote({ ...currentQuote });
    },

    deleteQuoteObjects: async (objectsToRemove, root) => {
      const { ID } = root.quote;

      await api.secure.delete(`/quote/${ID}/objects`, objectsToRemove);
    },

    updateCoveragesAndDeductibles: async ({ updates, session }, root) => {
      const { ID } = root.quote;
      const { Coverages, Deductibles, OtherStructures } = updates;

      const request = {
        Coverages: coveragesTransform.output(Coverages, root.quote.FormType),
        Deductibles,
        OtherStructures,
        ...session,
        FormType: root.quote.FormType,
      };

      const { data: response } = await api.secure.put(
        `/quote/${ID}/coverages`,
        request
      );

      dispatch.quote.setQuote({ Premium: response.Premium });
    },

    updateOptionalCoverage: async ({ updates, session }, root) => {
      const { ID } = root.quote;
      const { OptionalCoverages, DwellingInfo } = updates;

      delete OptionalCoverages.Premiums;

      const request = {
        Coverages: { ...OptionalCoverages },
        DwellingInfo,
        FormType: root.quote.FormType,
        ...session,
      };

      const paths = [
        'Coverages.PersonalPropertyIncreasedSpecialLimits.JewerlyWatchesFurs',
        'Coverages.PersonalPropertyIncreasedSpecialLimits.SilverwareGoldwarePewterware',
      ];

      if (
        request?.Coverages?.PermittedIncidentalOccupancyResidencePremises
          ?.BusinessLimit > 0
      ) {
        paths.push(
          'Coverages.PermittedIncidentalOccupancyResidencePremises.BusinessLimit'
        );
      }

      // Apply the conversion for each path
      paths.forEach((path) => convertPropertyToNumber(request, path));

      const { data: response } = await api.secure.put(
        `/quote/${ID}/optional-coverages`,
        request
      );

      dispatch.quote.setQuote({ Premium: response.Premium });
    },
    applyWindMit: async (formData, root) => {
      const { ID } = root.quote;

      const Questionnaire = formData.data.Questionnaire
        ? windMitTransform.input(
            JSON.parse(JSON.stringify(formData.data.Questionnaire))
          )
        : {};
      const windMitRequest = merge(
        {
          WindMitigationDiscount: !!formData?.data.WindMitigationDiscount,
          ...formData.Session,
        },
        formData?.data.WindMitigationDiscount
          ? {
              [formData.data.SelectedFormType]: {
                WindMitigationDiscount: formData?.data.WindMitigationDiscount,
                WindMitInspectionDate: formData.data.WindMitInspectionDate,
                WindMitFileName: formData.data.WindMitFileName,
                ...Questionnaire,
              },
            }
          : {}
      );
      const { data: windMitQuestionnaireResponse } = await api.secure.put(
        `/quote/${ID}/wind-mitigation`,
        windMitRequest
      );
      dispatch.quote.setQuote({
        windMitQuestionnaireResponse,
      });
    },

    applyDiscount: async (formData, root) => {
      const { ID } = root.quote;

      const Discounts = formData.data.Discounts;

      const discountRequest = {
        ...Discounts,
        ...formData.Session,
      };
      const { data: discountsResponse } = await api.secure.put(
        `/quote/${ID}/discounts`,
        discountRequest
      );
      dispatch.quote.setQuote(discountsResponse);
    },

    uploadWindMitForm: async ({ data }, root) => {
      const WindMitFile = data.WindMitFile;
      let fileName = null;
      if (WindMitFile) {
        fileName = await dispatch.quote.uploadAttachment({
          attachment: WindMitFile,
        });
      }

      dispatch.quote.setQuote({
        WindMitigationFormData: {
          InspectionDate: data.InspectionDate,
          File: fileName,
        },
      });
      return fileName;
    },

    upsertAdditionalInterests: async (payload, root) => {
      const { ID } = root.quote;

      let additionalInterestsRequest = { AdditionalInterests: [] };
      if (payload.AdditionalInterests) {
        payload.AdditionalInterests.forEach((additionalInterest) => {
          if (additionalInterest.isAdditional) {
            delete additionalInterest.isAdditional;
            additionalInterestsRequest.AdditionalInterests.push(
              additionalInterest
            );
          }
        });
      }
      if (payload?.CoApplicant) {
        additionalInterestsRequest.CoApplicant = payload.CoApplicant;
      }
      if (payload?.MailingAddress) {
        additionalInterestsRequest.ApplicantMailingAddress =
          payload.MailingAddress;
      }
      if (payload?.FirstMortgagee) {
        additionalInterestsRequest.AdditionalInterests.push({
          ...payload.FirstMortgagee,
        });
      }

      additionalInterestsRequest = {
        ...additionalInterestsRequest,
        FormType: root.quote.FormType,
        ...payload.Session,
      };

      await api.secure.put(
        `/quote/${ID}/additional-interests`,
        additionalInterestsRequest
      );
    },

    fetchQuote: async (_, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.get(`/quote/${ID}`);
      const { FirstName, LastName } = data.Customer;

      const quote = {
        ...data,
        FirstName,
        LastName,
      };

      switch (quote.FormType) {
        case 'HO6':
          quote.FormType = 'HO-6';
          break;
        case 'HO3':
          quote.FormType = 'HO-3';
          break;
        default:
          break;
      }

      await dispatch.quote.resetQuote(quoteTransform.input(quote));

      return quote;
    },

    fetchInitialValues: async (_, root) => {
      const { ID } = root.quote;
      const { data } = await api.secure.get(`/quote/${ID}`);

      const initialValues = quoteTransform.input(data);

      await dispatch.session.stageChanges(initialValues);

      return initialValues;
    },
    getPolicyNumber: async (body, { quote: { ID } }) => {
      const { data } = await api.secure.get(`/quote/${ID}/policy-number`);
      dispatch.quote.setQuote({
        PolicyNumber: data.PolicyNumber,
        PartyId: data.PartyId,
      });
      return { PolicyNumber: data.PolicyNumber, PartyId: data.PartyId };
    },

    getPolicyNumberWithPolicyId: async (payload, { quote: { ID } }) => {
      const { data } = await api.secure.get(
        `/quote/${ID}/policies/${payload.PolicyId}/policy-number`
      );
      dispatch.quote.setQuote({
        PolicyNumber: data.PolicyNumber,
      });
      return { PolicyNumber: data.PolicyNumber };
    },

    putPaymentPlan: async (payload, root) => {
      const { ID } = root.quote;

      let request = {
        PayPlan: payload.PaymentPlan,
        PolicyBillType: payload.PolicyBillingType,
        ...payload.Session,
      };

      if (payload.PolicyBillingType === 'PF1') {
        request = { ...request, PayPlan: payload.RenewalPlanCode };
      }

      const { data } = await api.secure.put(`/quote/${ID}/payment-plan`, {
        ...request,
        FormType: root.quote.FormType,
      });

      dispatch.quote.setQuote({ ...root.quote, ...data });
    },

    makePaymentAndIssue: async (body, { quote }) => {
      const { ID } = quote;
      const { data } = await api.secure.post(
        `/quote/${ID}/pay-and-issue`,
        body
      );

      dispatch.session.setSession({
        policy: {
          policyNumber: data.policyNumber,
          confirmationNumber: data.data.clientReferenceInformation.code,
          eSignatureLink: data.eSignatureLink,
        },
      });

      await dispatch.quote.fetchQuote();
    },

    getPaymentPlans: async (body, { quote: { ID } }) => {
      const { data } = await api.secure.get(`/quote/${ID}/payment-plan`, body);

      dispatch.quote.setQuote({ PayPlans: data.PayPlans });
    },

    uploadAttachment: async ({ attachment }, { quote: { ID } }) => {
      const body = new FormData();
      body.append('file', attachment);
      body.append('fileName', attachment.name);

      const { data } = await api.secure.post(`/quote/${ID}/attachments`, body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return data.fileName;
    },

    downloadAttachment: async ({ ID, attachment }) => {
      const file = await api.secure.get(
        `/quote/${ID}/attachments/${attachment.Id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
          },
          responseType: 'blob',
        }
      );
      const blob = new Blob([file.data], { type: 'application/pdf' });

      // Old MS Edge don't allow using a blob object directly as link href
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const objUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `${attachment.Title}.pdf`;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },

    getPolicyAttachment: async (
      { attachmentTitle },
      { quote: { PolicyNumber } }
    ) => {
      const file = await api.secure.get(
        `/policies/${PolicyNumber}/policy-attachment`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
          },
          responseType: 'blob',
        }
      );

      const blob = new Blob([file.data], { type: 'application/pdf' });

      // Old MS Edge don't allow using a blob object directly as link href
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
      } else {
        const objUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objUrl;
        link.download = `${attachmentTitle}.pdf`;
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL.
        setTimeout(() => {
          window.URL.revokeObjectURL(objUrl);
        }, 250);
      }
    },
  }),
};

export default quote;
